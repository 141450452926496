import Page from "../../components/page"
import SEO from "../../components/seo"
import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import Challenge from "../../components/challenge"
import ReferencesSection from "../../components/content-sections/references-section"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  BuildingBlock,
  BuildingBlocksGrid,
} from "../../components/building-blocks"
import { ContactForm } from "../../components/contact-form"
import {
  ExpertDisplay,
  ExpertDisplayStack,
} from "../../components/expert-display"
import { graphql, useStaticQuery } from "gatsby"
import { OneByOneGrid } from "../../components/layout/grid"

const ValidatingPage = ({ location }: { location: Location }) => {
  const { formatMessage } = useIntl()
  const {
    scalingImageData, // TODO swap !!
    johannesImageData,
    christianKImageData,
  } = useStaticQuery(
    graphql`
      query {
        scalingImageData: file(relativePath: { eq: "challenges/scaling.png" }) {
          ...DefaultImageOptions
        }
        johannesImageData: file(relativePath: { eq: "members/johannes.jpg" }) {
          ...DefaultImageOptions
        }
        christianKImageData: file(
          relativePath: { eq: "members/christian_k.jpg" }
        ) {
          ...DefaultImageOptions
        }
      }
    `
  )

  const scalingImage = getImage(scalingImageData)

  return (
    <Page>
      <SEO
        title={formatMessage({ id: "challenge.scaling.title" })}
        description={formatMessage({
          id: "challenge.scaling.shortDescription",
        })}
        // ogImage={} TODO
        location={location}
      />
      <Challenge kind={"scaling"}>
        <OneByOneGrid>
          <p>
            {formatMessage({ id: `challenge.scaling.description.intro` })}
            <br />
            <br />
            {formatMessage({ id: `challenge.scaling.description.main1` })}{" "}
            <b>{formatMessage({ id: `challenges.weHelpMakeADifference` })}</b>{" "}
            {formatMessage({ id: `challenge.scaling.description.main2` })}
            <br />
            <br />
            {formatMessage({ id: `challenges.weHelpToEmbed` })}{" "}
            <b>{formatMessage({ id: `challenges.drivingBottomUpChange` })}</b>
          </p>
          <div>
            <GatsbyImage image={scalingImage!} alt="" />
          </div>
        </OneByOneGrid>

        <h2>{formatMessage({ id: `challenges.howWeCanHelp` })}</h2>

        <BuildingBlocksGrid>
          <BuildingBlock kind={"scaling"} id={"modern-frontend-frameworks"} />

          <BuildingBlock kind={"scaling"} id={"cloud"} />

          <BuildingBlock kind={"scaling"} id={"microservices"} />

          <BuildingBlock kind={"scaling"} id={"containerization"} />

          <BuildingBlock kind={"scaling"} id={"cicd"} />

          <BuildingBlock kind={"scaling"} id={"scaled-agile"} />
        </BuildingBlocksGrid>

        <h2>{formatMessage({ id: `challenges.references` })}</h2>
        <ReferencesSection ids={["new-application", "serverless-backend"]} />

        <ContactForm
          title={formatMessage({ id: `challenges.getInTouch` })}
          initialMessage={"Hi Johannes, Christian \n\n"}
        >
          <ExpertDisplayStack>
            <ExpertDisplay
              name={"Johannes"}
              title={"Software Engineer"}
              description={"TODO: add short description"}
              imageData={johannesImageData}
            />
            <ExpertDisplay
              name={"Christian"}
              title={"Agile Practitioner"}
              description={"TODO: add short description"}
              imageData={christianKImageData}
            />
          </ExpertDisplayStack>
        </ContactForm>
      </Challenge>
    </Page>
  )
}

export default ValidatingPage
